import React, {useRef} from 'react';

import {motion, useScroll, useTransform} from 'framer-motion';
import {FormattedMessage} from 'react-intl';

import {IHeroPersonalizedSectionFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

type HeroPersonalizedSectionProps =
  ContentfulTemplateComponentProps<IHeroPersonalizedSectionFields>;

export const HeroPersonalizedSection: React.FC<HeroPersonalizedSectionProps> = (
  props
) => {
  const {
    entry: {
      fields: {
        landscapeBackgroundVideo,
        landscapeBackgroundImage,
        portraitBackgroundImage,
      },
    },
  } = props;

  const containerRef = useRef(null);
  const {scrollYProgress} = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });

  const yPos = useTransform(scrollYProgress, [0, 1], ['0%', '-250%']);

  const scrollIndicatorVariants = {
    initial: {y: 0, opacity: 0.8},
    animate: {
      y: [0, 10, 0],
      opacity: [0.8, 1, 0.8],
      transition: {
        y: {repeat: Infinity, duration: 1.5, ease: 'easeInOut'},
        opacity: {repeat: Infinity, duration: 1.5, ease: 'easeInOut'},
      },
    },
  };

  return (
    <div ref={containerRef} className="relative h-[250vh]">
      <div className="sticky top-0 h-[90vh] overflow-hidden">
        <motion.div className="absolute inset-0 z-10" style={{y: yPos}}>
          {landscapeBackgroundImage && (
            <img
              src={landscapeBackgroundImage.fields.file.url as string}
              alt="Background"
              className="w-full h-full object-cover"
            />
          )}

          <div className="absolute inset-x-0 bottom-0 h-2/5 bg-gradient-to-t from-black/100 to-transparent"></div>

          <div className="absolute text-white z-20 bottom-16 lg:bottom-8 right-8 lg:left-8  mb-8 lg:mb-20 ml-8 lg:ml-20">
            <motion.div
              className="mb-16 ml-8 relative w-72 h-72"
              initial={{opacity: 0, scale: 0.5}}
              animate={{opacity: 1, scale: 1.2}}
              transition={{duration: 1, delay: 0.3}}
            >
              {portraitBackgroundImage && (
                <img
                  src={portraitBackgroundImage.fields.file.url as string}
                  alt="Stryd device"
                  className="relative w-72 h-72 z-10 object-cover rounded-full"
                />
              )}
            </motion.div>
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold z-11 text-right lg:text-left">
              <FormattedMessage
                id="heroPersonalization.lineOne"
                defaultMessage="Run faster"
              />
              <br />
              <FormattedMessage
                id="heroPersonalization.lineTwo"
                defaultMessage="& stay healthy"
              />
            </h1>
          </div>
        </motion.div>
        <div className="absolute inset-0">
          {/* Fixed position text elements */}
          <div className="absolute text-white bottom-8 lg:bottom-28 left-8 lg:left-28  w-1/2 lg:w-1/3">
            <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold">
              <FormattedMessage
                id="heroPersonalization.subHeader"
                defaultMessage="Precision for a better run"
              />
            </h2>
            <p className="mt-2 font-medium text-lg pt-4">
              <FormattedMessage
                id="heroPersonalization.supportingOne"
                defaultMessage="Harness cutting-edge power metrics and real-time insights to optimize every run, anywhere you train."
              />
              <br />
              <br />
              <FormattedMessage
                id="heroPersonalization.supportingTwo"
                defaultMessage="Stryd helps you run smarter to optimize performance and running health."
              />
            </p>
          </div>
          {landscapeBackgroundVideo && (
            <video
              src={landscapeBackgroundVideo.fields.file.url as string}
              className="w-full h-full object-cover animate-fade-in"
              autoPlay
              loop
              muted
            />
          )}
        </div>
        <div className="absolute inset-0 items-center z-20 justify-center p-8 md:p-16 hidden lg:flex">
          <div className="w-full text-white">
            <motion.div
              className="absolute inset-x-0 mx-auto  bottom-0 mb-24 text-white w-48 flex flex-col items-center"
              variants={scrollIndicatorVariants}
              initial="initial"
              animate="animate"
            >
              <p className="text-md mb-2 font-bold">
                <FormattedMessage
                  id="heroPersonalization.scroll"
                  defaultMessage="Scroll to learn more"
                />
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5V19M12 19L5 12M12 19L19 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
