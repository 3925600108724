import React, {useRef} from 'react';

import {motion, useScroll, useTransform} from 'framer-motion';
import Image from 'next/image';
import {FormattedMessage} from 'react-intl';

import {ITypographySectionFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

import {WavyBackground} from '@/components/ui/wavy-background';
import {cn} from '@/lib/utils';

type TypographySectionProps =
  ContentfulTemplateComponentProps<ITypographySectionFields>;

export const TypographySection: React.FC<TypographySectionProps> = (props) => {
  const {
    entry: {
      fields: {header, one, two, three, images},
    },
  } = props;

  type Image = {
    fields: {
      file: {
        url: string;
      };
    };
  };

  //   const images2 =
  //     images?.map(
  //       (image: Image, index: number) =>
  //         convertToAbsoluteUrl(image.fields.file.url as string) as string
  //     ) ?? [];

  const images2 = [
    'https://images.ctfassets.net/8iip9pij3rta/65jcYrKe9M5a0EIKvjdSqm/13cf6e2c1c2943527819ec22df54c65d/AllRunners01.png',
    'https://images.ctfassets.net/8iip9pij3rta/2vXRvfZbmjDPfsaF9XoVVo/be17f4b8f588c0bcdc552d4cc2ee4db2/AllRunners05.png',
    'https://images.ctfassets.net/8iip9pij3rta/3uuFDqNeKAauoAHcznCxgy/1e1c93518340b34fd32db1513d968622/AllRunners03.png',
    'https://images.ctfassets.net/8iip9pij3rta/1hn2sjdZzlym78W14cJaUe/279f31c633aacba3b87e2fe4d9985515/AllRunners06.png',
    'https://images.ctfassets.net/8iip9pij3rta/1rnhNFGUqn4FsmCT0R4Rov/f30d6b02367577e2235dc12adbe823e7/AllRunners04.png',
    'https://images.ctfassets.net/8iip9pij3rta/5GCheZR4BaYmOUecH2IqWb/c9b7cbc9675153489177fb983565842c/AllRunners02.png',
    'https://images.ctfassets.net/8iip9pij3rta/5Jm0pZh5wknDsiRzWn7uc2/0c9f187f0ae559f5c85ce83378d89bee/AllRunners07.png',
    'https://images.ctfassets.net/8iip9pij3rta/63TjHVbJwDyWnUSaLMIK1W/12ba6b3afc740dad6a831c29a1bda4b5/AllRunners10.png',
    'https://images.ctfassets.net/8iip9pij3rta/655OrJ00UQtsmy9rWh9Oys/be8034e0517cf0597f23d48d1467ffae/AllRunners11.png',
    'https://images.ctfassets.net/8iip9pij3rta/1DLluPjLxV4qhN6xrSPL7c/06fa75a48987236541fac65bad06275c/AllRunners09.png',
    'https://images.ctfassets.net/8iip9pij3rta/2QxfS2mWjM9JkmDsGvAIBU/a5cc013f764380d27194c14bcda845bb/AllRunners12.png',
    'https://images.ctfassets.net/8iip9pij3rta/3QSDrtdY8ViIhVxUU2l73o/54accf8a4d24d88a8f259167cdd88e33/AllRunners08.png',
    'https://images.ctfassets.net/8iip9pij3rta/28r3xGQgXa9KlllmBGEZ5P/f8d8fe9a10b92beb894ef76eef634978/AllRunners17.png',
    'https://images.ctfassets.net/8iip9pij3rta/1n7bpWapyckFQsdilfNdK1/8420fed1f9fe675010082cb3960d6f80/AllRunners15.png',
    'https://images.ctfassets.net/8iip9pij3rta/4PW2cqV4q6Na61gtptoadJ/a0ba7c2bdd3852294a282464f81bb878/AllRunners13.png',
    'https://images.ctfassets.net/8iip9pij3rta/5mpamrHXempCbPCfqFQNU1/ce2bffdeeffb4ade6c0cc2f2175cd9a3/AllRunners14.png',
    'https://images.ctfassets.net/8iip9pij3rta/1wy0Qcockba6gEI1BLS4Wq/9ba7ccca45b2018bdde7e15d30bef50f/AllRunners16.png',
    'https://images.ctfassets.net/8iip9pij3rta/22gcrSymvsEggZsBiJTLlR/fa0c31d62dfbbee2d404408f275f04fd/AllRunners18.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/7KIOLn6eJuFYVw99vwpNW1/8701ab5822f70cce153d936b7fc35db1/AllRunners19.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/hKLGXOvbtjRNxFJsTP5Vn/2a57b66e1ffba103698ec799fbb59f5e/AllRunners20.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/1F57JuOEFXIOnYKP9G20jl/7edf147633a56a296a282d3756da38b5/AllRunners21.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/77mTeYmc2GrxXYSE3K3IDw/b6ea3543d02c1e00dc16457f38268c67/AllRunners24.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/WOVNuB1Erq4GENzAzhJ2Z/4f04cecb5fbe61259a9a5d0af2f7dee7/AllRunners25.png',
    // 'https://images.ctfassets.net/8iip9pij3rta/5yVtWUxrAzoDetDDK4F69c/515a2ce393a6cd650b15485582169039/AllRunners23.png',
  ];

  const gridRef = useRef<HTMLDivElement | null>(null);
  const {scrollYProgress} = useScroll();

  const translateYFirst = useTransform(scrollYProgress, [0, 1], [0, -200]);
  const translateXFirst = useTransform(scrollYProgress, [0, 1], [0, -200]);
  const rotateXFirst = useTransform(scrollYProgress, [0, 1], [0, -20]);

  const translateYThird = useTransform(scrollYProgress, [0, 1], [0, -200]);
  const translateXThird = useTransform(scrollYProgress, [0, 1], [0, 200]);
  const rotateXThird = useTransform(scrollYProgress, [0, 1], [0, 20]);

  const third = Math.ceil(images2.length / 3);

  const firstPart = images2.slice(0, third);
  const secondPart = images2.slice(third, 2 * third);
  const thirdPart = images2.slice(2 * third);

  const WhatIsStryd = () => (
    <div className="relative">
      <WavyBackground className="max-w-4xl mx-auto pb-40">
        <motion.div
          initial={{opacity: 0, y: 50}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.5}}
          className="max-w-6xl mx-auto text-center pt-12"
        >
          <motion.h1
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 0.2, duration: 0.5}}
            className="text-5xl md:text-6xl lg:text-7xl font-semibold text-gray-800 leading-tight mb-4"
          >
            <FormattedMessage id="whatIs.lineOne" defaultMessage="What is " />
            <span className="font-bold">
              <FormattedMessage id="whatIs.lineTwo" defaultMessage="Stryd?" />
            </span>
          </motion.h1>

          <motion.p
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 0.4, duration: 0.5}}
            className="max-w-4xl mb-8 text-center font-medium text-lg"
          >
            <FormattedMessage
              id="whatIs.supporting"
              defaultMessage="Stryd is a wearable device that clips onto your shoe, and
            communicates with your running watch or phone. Stryd helps you run
            the right intensity to balance your training, recovery, and
            performance by alerting you to slow down or speed up."
            />
          </motion.p>

          <motion.div
            initial={{opacity: 0, scale: 0.9}}
            animate={{opacity: 1, scale: 1}}
            transition={{delay: 0.6, duration: 0.5}}
            className="flex justify-center"
          >
            <img
              src="https://images.ctfassets.net/8iip9pij3rta/3WdrWJRRbC8ribncABFtua/73f07954df953451cbf22ccf95f963da/what-is-stryd.png"
              alt="Stryd device on a running shoe"
              className="max-h-[60vh]"
            />
          </motion.div>
        </motion.div>
      </WavyBackground>
    </div>
  );

  return (
    <>
      <div
        className={cn('h-full items-start overflow-y-auto w-full')}
        ref={gridRef}
      >
        <div className="max-w-6xl mx-auto text-center pt-12">
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-normal text-gray-800 leading-tight mb-4">
            <FormattedMessage
              id="typography.lineOne"
              defaultMessage="Stryd is used by"
            />{' '}
            <span className="italic font-bold">
              <FormattedMessage
                id="typography.lineTwo"
                defaultMessage="all runners"
              />
            </span>
            <br />
          </h2>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-stretch mt-4 lg:mt-10">
            <div className="w-full md:w-1/3 px-0 lg:px-6 mb-0 md:mb-0 lg:mb-6">
              <div className="pt-4 lg:pt-8 md:pt-0 md:pl-8 h-full flex flex-col items-center justify-center ">
                <h2 className="text-1xl md:text-2xl lg:text-32xl font-semibold">
                  <FormattedMessage
                    id="typography.beginners"
                    defaultMessage="Beginners"
                  />
                </h2>
                <p className="text-lg md:text-xl lg:text-1xl text-gray-600 font-medium leading-relaxed">
                  <FormattedMessage
                    id="typography.beginnersSupporting"
                    defaultMessage="Use Stryd to maintain a healthy, daily running routine"
                  />
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-0 lg:px-6 mb-0 md:mb-0 lg:mb-6">
              <div className="pt-4 lg:pt-8 md:pt-0 md:pl-8 h-full flex flex-col items-center justify-center ">
                <h2 className="text-1xl md:text-2xl lg:text-32xl font-semibold">
                  <FormattedMessage
                    id="typography.amateurs"
                    defaultMessage="Amateurs"
                  />
                </h2>
                <p className="text-lg md:text-xl lg:text-1xl text-gray-600 font-medium leading-relaxed">
                  <FormattedMessage
                    id="typography.amateursSupporting"
                    defaultMessage="Use Stryd set personal records with real-time guidance"
                  />
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-0 lg:px-6 mb-0 md:mb-0 lg:mb-6">
              <div className="pt-4 lg:pt-8 md:pt-0 md:pl-8 h-full flex flex-col items-center justify-center ">
                <h2 className="text-1xl md:text-2xl lg:text-32xl font-semibold">
                  <FormattedMessage
                    id="typography.pros"
                    defaultMessage="Pros"
                  />
                </h2>
                <p className="text-lg md:text-xl lg:text-1xl text-gray-600 font-medium leading-relaxed">
                  <FormattedMessage
                    id="typography.prosSupporting"
                    defaultMessage="Use Stryd set world records with lab-grade precision"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 items-start max-w-screen overflow-hidden lg:max-w-7xl mx-auto gap-0 lg:gap-10 py-20 px-0 mg:px-2 lg:px-10"
          ref={gridRef}
        >
          <div className="grid gap-10">
            {firstPart.map((el, idx) => (
              <motion.div
                style={{
                  y: translateYFirst,
                  x: translateXFirst,
                  rotateZ: rotateXFirst,
                }}
                key={'grid-1' + idx}
              >
                <Image
                  src={el}
                  className="h-80 w-full object-cover object-left-top rounded-lg gap-0 lg:gap-10 !m-0 !p-0"
                  height="400"
                  width="400"
                  alt="thumbnail"
                />
              </motion.div>
            ))}
          </div>
          <div className="grid gap-10">
            {secondPart.map((el, idx) => (
              <motion.div key={'grid-2' + idx}>
                <Image
                  src={el}
                  className="h-80 w-full object-cover object-left-top rounded-lg gap-0 lg:gap-10 !m-0 !p-0"
                  height="400"
                  width="400"
                  alt="thumbnail"
                />
              </motion.div>
            ))}
          </div>
          <div className="grid gap-10">
            {thirdPart.map((el, idx) => (
              <motion.div
                style={{
                  y: translateYThird,
                  x: translateXThird,
                  rotateZ: rotateXThird,
                }}
                key={'grid-3' + idx}
              >
                <Image
                  src={el}
                  className="h-80 w-full object-cover object-left-top rounded-lg gap-0 lg:gap-10 !m-0 !p-0"
                  height="400"
                  width="400"
                  alt="thumbnail"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <WhatIsStryd />
    </>
  );
};
